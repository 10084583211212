/* symcsm scss goes here */

div.logo {
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

div.logo img.logo {
    max-height: 90px;
    width: auto;
    padding-left: 1rem;
}

p.logo {
  font-weight: bold;
}

$anchor-font-family: 'Poppins';

li a {
    font-family: $anchor-font-family;
    text-transform: uppercase;

    span.badge {
        margin-left: 0.5rem;
    }
}
li span.no_link {
    font-family: $anchor-font-family;
    text-transform: uppercase;
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: $global-menu-padding;

    span.badge {
        margin-left: 0.5rem;
    }
}

div.content,div.page-header .grid-x{
    background-color: white;
}

/*
div.content::after {
	  content: "";
	  background: url(../images/blur-green.png);
	  width: 218px;
	  height: 234px;
	  display: block;
	  position: absolute;
	  right: -7%;
	  bottom: -5%;
	  z-index: -1000;
}

div.content::before {
	  content: "";
	  background: url(../images/blur-blue.png);
	  width: 89px;
	  height: 88px;
	  display: block;
	  position: absolute;
	  left: 4%;
  	  z-index: -1000;
}
*/

th {
    font-size: 0.875rem;
    label {
        font-weight: bold; 
    }
}

a.activity-feed {
  text-transform: none;
}

span.activity-feed-time {
  font-family: "Poppins";
}

ul.activity-feed li {
  list-style-type: none;
}

tr {
    cursor: pointer;
}

div.nav-tile {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 8px;
}

div.attachment {
  margin-right: 32px;
  min-height: 100px;
  min-width: 100px;
}

div.attachment img {
  max-width: 100px;
  height: auto;
}

div.attachment p {
  float: bottom;
}

p.attachment-text {
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
}

div#attachment-pdf-container {
  height: 1200px;
}

textarea[rows] {
  height: 7.2em;
}

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; min-width: 40vw; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

ul.errorlist {
  color: #d60050;
  list-style-type: none;
}

span.status-icons {
    font-size: 20px;
}

/*
tr.chargeable:nth-child(odd) {
  background-color: #dbffe8 !important;
}

tr.chargeable {
  background-color: #c8e9d4 !important;
}

tr.overdue:nth-child(odd) {
  background-color: #fffea9 !important;
}

tr.overdue {
  background-color: #e9e890 !important;
}

tr.urgent-priority:nth-child(odd) {
  background-color: #ffe6ef !important;
}

tr.urgent-priority {
  background-color: #eec8d6 !important;
}

tr.low-priority:nth-child(odd) {
  background-color: rgba(200, 248, 255, 0.62) !important;
}

tr.low-priority {
  background-color: #b7dced !important;
}
*/

tr.alert:nth-child(even) {
  background-color: #ffc0c0 !important;
}

tr.alert:nth-child(odd) {
  background-color: #ffdcdc !important;
}

.bold {
  font-weight: bold;
}

td.customer-details {
  min-width: 200px;
}

th + th, td + td {
  border-left: #f1f1f1 1px solid;
}

div.footer {
  font-size: 14px;
}

div.footer-text {
  padding-top: 18px;
}

.button.failure {
  background-color: #b94f4f;
}

tr.save-row {
  text-align: right;
}

tr.save-row input.button {
  min-width: 120px;
  min-height: 45px;
}

.button + .button {
  margin-left: 4px;
}

div.status-icons {
  display: flex;
  flex-direction: row;
}

div.status-icon {
  min-width: 28px;
  padding: 2px;
}

img.status-icon {
  width: 24px;
  height: auto;
}

.nav-bottom-image {
  opacity: 0.55;
  width: 24px !important;
  height: auto;
  color: gray;
}

span.hours-summary {
  font-style: italic;
  color: gray;
}

div.breadcrumbs {
  padding-left: 0.9375rem;
}

div.breadcrumbs .separator {
  color: #0085c1;
}

tr.unreliable {
  color: red !important;
}

td input + br {
  display: none;
}

.helptext {
  color: gray;
}

ul.hide-bullets li {
  list-style-type: none;
}

div.responsive-wrapper {
  display: flex;
  flex-direction: column;
}

div.responsive-row {
  display: flex;
  height: 100%;
}

div.responsive-cell {
  display: flex;
  flex-direction: column;
}

p.th {
  font-weight: bold;
  font-size: 0.875rem;
}

@media screen and (orientation: landscape) {
  div.responsive-row {
      flex-direction: row;
  }

  div.responsive-cell + div.responsive-cell {
    margin-left: 20px;
  }

  div.responsive-cell {
    height: 100%;
  }

  div.responsive-cell textarea {
    height: 75%;
  }
}

@media screen and (orientation: portrait) {
  div.responsive-row {
      flex-direction: column;
  }

  div.responsive-row + div.responsive-row {
    border-top: 1px solid lightgray;
    margin-top: 16px;
    padding-top: 24px;
  }

  .mobile-hide {
    display: none;
  }

  div.nav-tile {
    min-width: 110px;
    width: 110px;
    min-height: 110px;
    height: 110px;
    font-size: 16px;
  }
}

.hide-me-now {
  display: none;
}

.urgent {
  color: red !important;
}

div.quick-details {
  padding: 8px 0 8px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

button.quick-details + button.quick-details {
  margin-left: 8px;
}

span.negative {
  color: red;
}

button[type="submit"].save {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  min-width: 120px;
  min-height: 45px;
}

button[type="submit"].save:before {
  content: '💾 ';
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.random-icon {
  border: 1px solid #d3d3d3;
  position: relative;
  margin-left: 0.9375rem;
}